:root {
  /*COLORS*/
  --primary-color: #00244f; /*dark blue*/
  --secondary-color: #88beff; /*light blue*/
  --secondary-color-alpha: hsla(213, 100%, 77%, 0.2);
  --primary-neutral-color: #fff; /*white*/
  --secondary-neutral-color: #f8f8f8; /*grey-white*/
  --decorative-color-shaded: hsla(30, 95%, 57%, 0.5);
  --decorative-color-shaded: hsla(28, 95%, 57%, 0);

  /*FONTS STACK*/
  --primary-font: "Mulish", "Arial", sans-serif;
  --secondary-font: "Inter", "Calibri", sans-serif;
  --tertiary-font: "IBM Plex Serif", "Times New Roman", serif;

  /*FONTS WEIGHT*/

  /*FONTS SIZE*/

  /*MARGIN*/
  --general-left-right-margin: 5%;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: var(--primary-font);
}
h2 {
  font-family: var(--primary-font);
  font-size: 2.2rem;
}
h3 {
  font-family: var(--primary-font);
}
blockquote {
  margin: 0;
  padding: 0;
  font-family: var(--tertiary-font);
  font-style: italic;
  font-weight: 300;
}
form {
  /*border: solid yellow;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 50ch;
}
input {
  background-color: hsla(0, 0%, 0%, 0);
  min-height: 50px;
  border: var(--secondary-neutral-color) solid 0.5px;
  border-radius: 5px;
  color: var(--primary-neutral-color);
  border: var(--secondary-neutral-color) solid 0.5px;
  /*padding-left: 1rem;*/
  padding-left: 0rem;
  margin: 0;
}
textarea {
  resize: none;
  background-color: hsla(0, 0%, 0%, 0);
  border-radius: 5px;
  min-height: 200px;
  border: var(--secondary-neutral-color) solid 0.5px;
  /*padding: 1rem 0 1rem 1rem;*/
  color: var(--primary-neutral-color);
  font-family: var(--secondary-font);
  padding-top: 0.5rem;
}
::placeholder {
  color: var(--secondary-neutral-color);
  font-family: var(--secondary-font);
  font-weight: 200;
  padding-left: 0.5rem;
}
input:focus {
  outline: 1.5px solid var(--primary-neutral-color);
}
input:focus::placeholder,
textarea:focus::placeholder {
  color: transparent;
}

input[type="submit"] {
  text-transform: uppercase;
  background-color: var(--primary-neutral-color);
  color: var(--primary-color);
  font-size: 0.85rem;
  padding: 1em 1.5em;
  cursor: pointer;
}
input[type="submit"]:hover {
  color: var(--secondary-neutral-color);
  background-color: var(--secondary-color);
  transition: all ease-in 0.2s;
}

/*FLEX STUFF*/
.flex-end {
  justify-content: end;
}
.flex-start {
  justify-content: start;
}

/*BUTTONS*/
.btn {
  font-size: 1rem;
  text-decoration: none;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  border-radius: 0.4em;
  cursor: pointer;
}
.blue-btn {
  color: var(--primary-neutral-color);
  background-color: var(--primary-color);
  font-family: var(--primary-font);
  font-weight: 600;
  margin-bottom: 2rem;
}
.blue-btn:hover {
  color: var(--secondary-neutral-color);
  background-color: var(--secondary-color);
  transition: all ease-in 0.2s;
}

.white-btn {
  color: var(--primary-color);
  background-color: var(--primary-neutral-color);
  font-family: var(--secondary-font);
  font-weight: bold;
}
.white-btn:hover {
  color: var(--secondary-neutral-color);
  background-color: var(--secondary-color);
  transition: all ease-in 0.2s;
}

/*---------HEADER SECTION---------*/
.header-wrapper {
  background-color: var(--primary-color);
}
.header-container {
  max-width: 80%;
  margin: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*border: solid, white;*/
}
.header-container img {
  max-width: 150px;
  /*border: solid red;*/
}

.icon {
  max-height: 55px;
  padding-right: 1rem;
}

.lang-btn {
  color: var(--primary-neutral-color);
  font-family: var(--secondary-font);
  border: none;
  border-left: solid white 0.5px;
  border-right: solid white 0.5px;
  background-color: transparent;
  cursor: pointer;
  padding-bottom: 0;
  padding-top: 0;
}
.lang-btn:first-child {
  border-right: none;
}

.lang-btn:active {
  text-decoration: underline;
}
.underlined {
  text-decoration: underline;
}
/*
.header-container > a:link, BOUTON HEADER CONTACTEZ NOUS, voir plus haut a:link et a:visited pour les dimentions générales
a:visited {
  color: var(--primary-color);
  background-color: var(--primary-neutral-color);
  font-family: var(--secondary-font);
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  border-radius: 0.4em;
  cursor: pointer;
}
*/

/*------HERO SECTION-------- */
.hero-section-wrapper {
  /*border: #09bed6 solid;*/
  background-color: var(--primary-color);
  position: relative;
}
.hero-container {
  /*border: #fff solid;*/
  margin: auto;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}

.hero-title h1 {
  color: var(--primary-neutral-color);
  font-weight: 400;
  font-size: 3.4rem;
  letter-spacing: -2px;
}
.hero-title span {
  color: var(--secondary-color);
  font-weight: 400;
  font-size: 3.5rem;
  letter-spacing: -2px;
}
.hero-title span strong {
  font-weight: bold;
  letter-spacing: -0px;
}

.hero-2 {
  /*border: #fd0202 solid;*/
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.hero-2 ul {
  /*border: #000 solid;*/
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style-position: inside;
}
.hero-2 li {
  color: var(--primary-neutral-color);
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 300;
  padding-bottom: 0.6em;
}
li img {
  display: block;
  padding-top: 0.2em;
  max-width: 2ch;
}
.hero-quote-container {
  /*border: #000 solid;*/
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.hero-quote-container p {
  color: var(--secondary-color);
  font-size: 2rem;
  padding: 0;
  margin: 0;
  max-width: 40ch;
}
.hero-quote-container cite {
  display: block;
  color: var(--primary-neutral-color);
  font-size: 1.05rem;
  font-weight: 400;
  margin-top: 1em;
  margin-bottom: 0.4em;
}
.hero-quote-container .quotation-marks {
  max-width: 1.3em;
}
.hero-quote-container img {
  max-width: 150px;
}

.three-branches-blop {
  position: absolute;
  left: -10ch;
  bottom: -7ch;
  z-index: 0;
  /*max-width: 250px;*/
  max-width: 15%;
}

/*OUR MISSION*/
.our-mission-wrapper {
  /*border: solid brown;*/
  background-color: var(--primary-neutral-color);
}

/*PROJECT*/
.project-container {
  /*border: solid red;*/
  margin: auto;
  display: flex;
  max-width: 80%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.project-container h2 {
  color: var(--primary-color);
  margin-bottom: 1.5em;
  text-align: center;
}
.project-container p {
  color: var(--primary-color);
  font-weight: 500;
  font-size: 1.2rem;
  /*max-width: 25ch;*/
  text-align: center;
  margin-top: 0;
}
.project-container img {
  height: 100%;
}
.flex-row {
  /*border: solid blue;*/
  display: flex;
  flex-wrap: wrap;
  min-height: 40ch;
  gap: 3rem;
}
.flex-col-center-element {
  /*border: solid yellow;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex: 1;
}
.flex-col-center-wrapper {
  /*border: solid orange;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0rem 2rem 1rem 2rem;
}

/*PARTENAIRE*/
.partenaire-section {
  margin-bottom: 3rem;
  margin-top: 2rem;
}

.partenaire-section h2 {
  font-style: italic;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}
.partenaire-logo {
  display: flex;
  justify-content: center;
  gap: 10rem;
}
.partenaire-logo img {
  max-width: 10%;
}
/*CE QUE NOUS POUVONS FAIRE*/

.offer-card {
  border-top: 1px solid transparent;
  border-image: linear-gradient(
      to right,
      hsla(36, 100%, 49%, 1),
      hsla(0, 40%, 62%, 1),
      hsla(213, 100%, 77%, 1)
    )
    1 0 0 0 / 5px / 0px round;
  padding: 1rem 2rem;
  margin-bottom: 2rem;
  box-shadow: 0px 4px 4px 5px hsla(0, 0%, 0%, 0.2);
}
.offer-card button {
  font-family: var(--secondary-font);
  color: var(--primary-color);
  background-color: transparent;
  font-size: 0.9rem;
  padding: 0.5em 0.3em;
  cursor: pointer;
  border: none;
  text-decoration: underline;
  padding: 0;
}
.offer-card button:hover {
  text-decoration: none;
}
.offer-header h3 {
  color: var(--primary-color);
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
}
.offer-header p {
  font-size: 1.2rem;
  font-weight: 400;
  text-align: left;
  color: var(--primary-color);
}
.offer-detail {
  /*border: solid blue;*/
  margin: 2rem 0 1rem 0;
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 0 2rem;
  flex-wrap: wrap;
}

.offer-detail div:last-child {
  /*border: solid green;*/
  flex-basis: 60%;
}
.offer-detail div p:first-child {
  color: #9d9d9d;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0.3em;
  font-style: normal;
}
.offer-detail p {
  /*border: solid blue;*/
  color: #9d9d9d;
  font-family: var(--secondary-font);
  font-weight: 100;
  font-size: 1rem;
  font-style: italic;
}

.offer-example p {
  /*border: solid blue;*/
  text-align: left;
  font-family: var(--secondary-font);
  color: var(--primary-color);
  margin-top: 0.5rem;
}
.offer-example p:first-child {
  margin-right: 2rem;
  margin-bottom: 0.5rem;
  color: var(--primary-color);
}
.offer-example p:last-child {
  font-weight: 200;
  font-size: 1rem;
  color: var(--primary-color);
}
.offer-example a {
  text-decoration: none;
  color: hsla(36, 100%, 49%, 1);
  font-style: italic;
  text-decoration: underline;
}

/*mécanique pour la transition des élements caché dans offer*/
.offer-hide {
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  max-height: 0; /* Départ de l'animation : hauteur nulle */
  opacity: 0; /* Départ de l'animation : complètement transparent */
}

.offer-hide-active {
  max-height: 500px; /* Mettez ici la hauteur maximale que le contenu pourrait avoir */
  opacity: 1; /* Fin de l'animation : complètement opaque */
}

/*ENGAGEMENT*/
.value-wrapper {
  /*border: solid blue;*/
  background-color: var(--primary-neutral-color);
  overflow-x: hidden;
}

.engagement-wrapper {
  /*border: solid green;*/
  background-color: var(--secondary-neutral-color);
  /*display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;*/
  min-height: 80ch;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  position: relative;
}
.engagement-container {
  /*border: solid red;*/
  max-width: 50%;
  margin: auto;
  background-color: var(--primary-color);
  color: var(--primary-neutral-color);
  margin-bottom: 2rem;
  padding: 2rem 0;
}

.engagement-container h2 {
  margin-top: 4rem;
  margin-left: 4rem;
  margin-right: 4rem;
}
.engagement-container p {
  margin: 4rem;
  max-width: 50ch;
  font-size: 1.2rem;
  font-family: var(--primary-font);
  font-weight: 300;
}
.engagement-container em {
  max-width: 50ch;
  font-size: 1.09em;
  font-family: var(--primary-font);
  font-weight: 700;
  text-decoration: underline;
}

.nature-quote-container {
  /*border: solid pink;*/
  max-width: 50%;
  margin: auto;
  text-align: center;
}
.nature-quote-container p {
  color: var(--primary-color);
  font-size: 2rem;
  font-style: italic;
}
.nature-quote-container cite {
  display: block;
  color: var(--primary-color);
  font-size: 1.05rem;
  font-weight: 400;
  margin-top: 0em;
  margin-bottom: 0.4em;
}
.nature-logo {
  max-width: 20%;
  min-width: 10ch;
}
.blop-right {
  position: absolute;
  z-index: 0;
  right: -8ch;
  bottom: -10ch;
  /*max-width: 180px;*/
  max-width: 13%;
  transform: rotate(90deg);
}

/*ACCOMPAGNEMENT*/
/*.accompagnement-wrapper {
}*/
.accompagnement-container {
  /*border: solid red;*/
  max-width: 50%;
  margin: auto;
  background-color: var(--primary-color);
  color: var(--primary-neutral-color);
  margin-bottom: 5rem;
  margin-top: 5rem;
  padding: 2rem 0;
}

.accompagnement-container h2 {
  margin-top: 4rem;
  margin-left: 4rem;
  margin-right: 4rem;
}
.accompagnement-container ul {
  font-size: 1.2rem;
  font-family: var(--primary-font);
  font-weight: 200;
  list-style-position: outside;
  list-style-type: none;
  padding: 0;
  margin: 4rem 4rem 4rem 4rem;
}
.accompagnement-container li {
  margin-top: 1em;
  margin-bottom: 1em;
}

.capital-text {
  text-transform: uppercase;
  font-size: 1.1em;
}
.bolded-text {
  font-weight: 600;
}

/*NOS SERVICES*/
.our-services-wrapper {
  /*border: saddlebrown solid;*/
  background-color: var(--primary-color);

  color: var(--primary-neutral-color);
}
.our-services-wrapper h2 {
  /* border: solid;*/
  text-align: center;
}
.our-services-container {
  /*border: solid yellow;*/
  padding: 2rem 0rem;
  max-width: 80%;
  margin: auto;
}

.services-card-panel {
  /* border: solid crimson;*/
  margin: 0 4rem 4rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.service-row {
  /*border: green solid;*/
  display: flex;
  justify-content: center;
  gap: 4rem;
  flex-wrap: wrap;
}
.service-card {
  border: white solid;
  flex: 1;
  padding: 2rem;
  max-width: 400px;
  background-color: var(--secondary-color-alpha);
  border-radius: 5px;
  background-image: radial-gradient(
    circle at top left,
    hsla(30, 95%, 57%, 0.7),
    var(--secondary-color-alpha) 30%
  );
}
.service-card h3 {
  font-size: 1.6rem;
}
.service-card p {
  font-size: 1.25rem;
  font-weight: 300;
  margin-top: 0;
}
.service-card img {
  max-width: 60px;
}

/*Gamification Pros*/
.pros-container {
  /*border: solid yellow;*/
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.pros-container h2 {
  text-align: center;
  color: var(--primary-color);
}
.pros-card-container {
  /*border: solid red;*/
  max-width: 60%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.pros-card {
  /* border: solid green;*/
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  min-height: 220px;
  max-width: 700px;
  padding: 0 2rem 0 0.5rem;
  gap: 2rem;
}
.card-v1 {
  background-color: var(--primary-color);
  color: var(--primary-neutral-color);
  position: relative;
  left: -10%;
}
.card-v2 {
  background-color: var(--secondary-color-alpha);
  color: var(--primary-color);
  position: relative;
  left: +10%;
}

.pros-card h3 {
  font-size: 1.6rem;
}
.pros-card p {
  font-size: 1.25rem;
  font-weight: 300;
  margin-top: 0;
  max-width: 55ch;
}
.pros-card-number {
  display: inline;
}
.pros-card-description {
  display: inline;
  align-self: center;
}
.blop-left {
  position: absolute;
  bottom: -5ch;
  left: -9ch;
  max-width: 10%;
}

/*******************/
/*FAQ*/
.FAQ-wrapper {
  /* border: solid red;*/
  padding: 2rem;
  background-color: var(--secondary-neutral-color);
}
.FAQ-container {
  /*border: solid blue;*/
  margin: auto;
  max-width: 80%;
  display: flex;
}

.FAQ-cta h2 {
  color: var(--primary-color);
  margin: 0;
}
.FAQ-cta p {
  color: var(--primary-color);
  font-size: 1.1rem;
  margin-bottom: 2rem;
}
.FAQ-panel {
  /*border: solid salmon;*/
  margin-left: 2rem;
  margin-top: 0.5rem;
  max-width: 70%;
}
.FAQ-item {
  margin-bottom: 1rem;
  padding: 0 1rem;
  border-radius: 0.3rem;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15);
  background-color: var(--primary-neutral-color);
}
.FAQ-item h3 {
  color: var(--primary-color);
  margin: 0;
  padding: 0;
}

.FAQ-question {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin: 0;
  padding: 1rem 0;
}
.plus-minus {
  margin: 0;
  padding: 0;
  font-size: 2rem;
  line-height: 0.5;
}
.FAQ-answer p {
  color: var(--primary-color);
  font-size: 1.05rem;
  margin-top: 0;
}

/*FOOTER*/
#footer {
  /*border: solid red;*/
  background-color: var(--primary-color);
}
.footer-container {
  /*border: yellow solid;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 2rem 2rem 2rem;
}
.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title-container h2 {
  color: var(--primary-neutral-color);
  font-family: var(--secondary-font);
  font-weight: 400;
  padding-bottom: 0;
  margin-bottom: 0;
  text-transform: uppercase;
}
.title-container p {
  color: var(--primary-neutral-color);
  font-weight: 300;
  font-size: 1.1rem;
}

.name-mail-row {
  /*border: solid red;*/
  display: flex;
  margin-bottom: 1rem;
  width: 100%;
}
.name-mail-row input {
  width: 48%;
}
.name-mail-row input:first-child {
  margin-right: auto; /* Pousse le deuxième élément input à droite */
}

.subject-row,
.subject-row input {
  /* border: solid pink;*/
  width: 100%;
}

.message-row,
.message-row textarea {
  /*border: solid pink;*/
  width: 100%;
}
.adresse-wrapper {
  /*border: solid gray;*/
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--secondary-neutral-color);
  text-align: center;
  font-weight: 200;
}
address {
  /*border: solid pink;*/
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  color: var(--secondary-neutral-color);
  flex-wrap: wrap;
}
address a {
  /*border: solid yellow;*/

  text-decoration: none;
  color: var(--secondary-neutral-color);
}
address p {
  /* border: solid yellow;*/

  line-height: 0;
}
form p {
  font-size: 0.8rem;
  color: var(--primary-neutral-color);
  align-self: self-start;
}

/*RESPONSIVE !*/

@media all and (max-width: 780px) {
  h2 {
    font-size: 1.8rem;
  }

  /*HERO CONTAINER*/
  .hero-container h1,
  .hero-container span,
  .hero-container strong {
    font-size: 2.3rem;
  }
  .hero-container p {
    font-size: 1.5rem;
  }
  .hero-container li {
    font-size: 1rem;
  }
  .hero-quote-container img {
    max-width: 100px;
  }

  /*CE QU'ON PEUT FAIRE*/
  .offer-detail {
    padding: 0 0.5rem;
  }
  .offer-detail div:last-child {
    flex-basis: 100%;
  }

  /*ENGAGEMENT*/
  .engagement-container {
    /*border: solid red;*/
    max-width: 90%;
  }
  .nature-quote-container {
    /*border: solid pink;*/
    max-width: 80%;
  }
  .nature-quote-container p {
    font-size: 1.8rem;
  }
  /*ACCOMPAGNEMENT*/
  .accompagnement-container {
    /*border: solid red;*/
    max-width: 90%;
  }
  .accompagnement-container ul {
    font-size: 1.12rem;
    font-family: var(--primary-font);
    font-weight: 200;
    list-style-position: outside;
    list-style-type: none;
    padding: 0;
    margin: 4rem 4rem 4rem 4rem;
  }

  /*NOS SERVICES*/
  .services-card-panel {
    /*border: solid crimson;*/
    gap: 2rem;
  }
  .service-row {
    /*border: green solid;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
  }
  /*GAMIFICATION PROS*/
  .pros-card-container {
    /*border: solid red;*/
    max-width: 90%;
  }
  .card-v1 {
    position: static;
  }
  .card-v2 {
    position: static;
  }
  .pros-card h3 {
    font-size: 1.4rem;
  }
  .pros-card-number img {
    max-width: 100%;
  }

  /*FAQ*/
  .FAQ-container {
    /*border: solid blue;*/
    margin: auto;
    max-width: 90%;
    flex-direction: column;
  }
  .FAQ-cta {
    margin-bottom: 1rem;
  }
  .FAQ-panel {
    /* border: solid salmon;*/
    margin-left: 0rem;
    max-width: 100%;
  }
  /*FOOTER*/
}

/*media for footer footer*/
@media all and (max-width: 613px) {
  address {
    display: block;
    text-align: center;
  }

  .name-mail-row {
    flex-direction: column;
    width: 100%;
  }
  .name-mail-row input {
    width: 100%;
  }
  .name-mail-row input:first-child {
    margin-bottom: 1rem;
  }
}

@media all and (max-width: 581px) {
  .white-btn {
    font-size: 0.75rem;
  }
  /*HEADER*/

  /*HERO*/
  .hero-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .hero-container h1,
  .hero-container span,
  .hero-container strong {
    font-size: 1.8rem;
  }
  .hero-container p {
    font-size: 1.2rem;
  }
  .hero-container li {
    font-size: 0.8rem;
  }

  .hero-container cite {
    font-size: 1rem;
  }
  .hero-quote-container img {
    max-width: 80px;
  }

  .hero-2 {
    flex-direction: column;
    align-items: center;
  }
  .hero-2 ul {
    display: flex;
    text-decoration: underline;
    text-decoration-color: var(--secondary-color);
    text-decoration-thickness: 1px;
    gap: 1rem;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
  }
  .hero-2 ul img {
    display: none;
  }

  /*PARTENAIRE*/

  .partenaire-logo {
    gap: 5rem;
  }
  .partenaire-logo img {
    max-width: 20%;
  }

  /*ENGAGEMENT*/

  /*ACCOMPAGNEMENT*/

  /*NOS SERVICES*/
  .our-services-container {
    /*border: solid yellow;*/
    max-width: 90%;
  }
  /*GAMIFICATION PROS*/
  .pros-card-container {
    /*border: solid red;*/
    max-width: 100%;
  }
  .pros-card p {
    font-size: 1.1rem;
  }

  .pros-card-number img {
    max-width: 100%;
  }
  /*FAQ*/
  /*FOOTER*/
  form {
    width: 90vw;
  }
}

@media all and (max-width: 426px) {
  /*HERO*/
  /*ENGAGEMENT*/

  /*ACCOMPAGNEMENT*/

  /*NOS SERVICES*/
  .our-services-container {
    /*border: solid yellow;*/
    max-width: 100%;
  }
  .services-card-panel {
    /* border: solid crimson;*/
    margin: 0 1rem 4rem 1rem;
  }

  /*GAMIFICATION PROS*/
  .pros-container {
    /*border: solid yellow;*/
    padding: 1rem;
  }
  .pros-card {
    /* border: solid green;*/
    gap: 0.2rem;
  }
  /*FAQ*/
  .FAQ-wrapper {
    /* border: solid red;*/
    padding: 2rem 1rem;
    background-color: var(--secondary-neutral-color);
  }
  .FAQ-container {
    /*border: solid blue;*/
    max-width: 100%;
  }
  .FAQ-panel {
    /*border: solid blue;*/
    max-width: 100%;
  }
  /*FOOTER*/
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 90vw;
  }
}

@media all and (max-width: 360px) {
  /*BUTTON*/
  .white-btn {
    font-size: 0.75rem;
  }
  /*HEADER*/
  .header-container {
    max-width: 90%;
  }
  .header-container img {
    max-width: 120px;
  }
  /*HERO*/
  .hero-container {
    max-width: 90%;
  }

  /*PARTENAIRE*/

  .partenaire-section h2 {
    font-style: italic;
    font-weight: 400;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  .partenaire-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
  }
  .partenaire-logo img {
    max-width: 30%;
  }
  /*ENGAGEMENT*/
  .engagement-container {
    max-width: 95%;
  }
  .engagement-container h2 {
    margin-top: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .engagement-container p {
    font-size: 1rem;
    margin: 2rem;
    max-width: auto;
    font-size: 1.2rem;
    font-family: var(--primary-font);
    line-height: 1.15;
  }
  .nature-quote-container {
    /*border: solid pink;*/
    max-width: 95%;
  }
  .nature-quote-container p {
    font-size: 1.4rem;
  }
  /*ACCOMPAGNEMENT*/
  .accompagnement-container {
    /*border: solid red;*/
    max-width: 95%;
  }
  .accompagnement-container h2 {
    /*border: solid red;*/
    margin-left: 1.5rem;
    margin-top: 1rem;
  }
  .accompagnement-container ul {
    /*border: solid red;*/
    margin-left: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  /*NOS SERVICES*/

  /*GAMIFICATION PROS*/

  /*FAQ*/
  .FAQ-item h3 {
    font-size: 1.05rem;
  }
  /*FOOTER*/
}
